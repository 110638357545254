<template>
    <div>
         <main>
             <!-- 1  v-lazy:background-image="require('./photo/playybanner.jpg')" -->
     <div class="banner" >
     <img v-lazy="require('./photo/HYm3DWoB.webp')">
        <!-- 横幅内容区域 -->
       <div class="qwe">
            <h2 class="title">游园详情</h2>
        <p class="introduce">
          独特的景区内涵，增加游客体验
        </p>
       </div>
        <div class="mork"></div>
      </div>
      <!-- 2 730 -->
      <div class="twobox">
         <div class="texttwo">方案优势</div>
         <div class="imgboxtwo">
     <div class="imgtextwo">
         <div class="imgone"></div>
         <div class="textbig">
             <div class="wenzione">AI智能</div>
              <div class="wenzitwo">AI 识物,识景,更快速的解决游客的烦恼,同时降低景区的人力成本。</div>
         </div>
     </div>
     <div class="imgtextwo">
         <div class="imgone"></div>
         <div class="textbig">
             <div class="wenzione">游戏互动</div>
              <div class="wenzitwo">自行设计,丰富的游戏玩法,增加游客在景区内的停留时长。</div>
         </div>
     </div>
     <div class="imgtextwo">
         <div class="imgone"></div>
         <div class="textbig">
             <div class="wenzione">数据预测</div>
              <div class="wenzitwo">对景区游客属性进行分析,同时预测未来人流趋势等信息,更加便于景区的营销和安全管理。</div>
         </div>
     </div>
         </div>
      </div>
     <!-- 3 720 -->
       <div class="threebox">
<div class="threeone">服务理念</div>
<div class="threetwo">完善的运营服务主要是以服务质量与环境质量方面整改提升为主，提供全程指导服务，包括问题解决方案编制、模拟运行，专业运营指导现场指导</div>
<div class="threethree">优秀的设计理念</div>
<div class="threefour">主要以品牌创建为主，提供全程咨询辅导服务 自行自主设计每一步都走在行业的前面</div>
       </div>
     <!-- 4  546px   -->
     <div class="fourbox">
     <div class="textfour">自主创新</div>
     <div class="imgfour">
         <div class="fourboximg">
             <div class="imgsm"></div>
             <div class="imgtext">自主设计</div>
         </div>
          <div class="fourboximg">
             <div class="imgsm"></div>
             <div class="imgtext">区别传统</div>
         </div>
          <div class="fourboximg">
             <div class="imgsm"></div>
             <div class="imgtext">创造价值</div>
         </div>
          <div class="fourboximg">
             <div class="imgsm"></div>
             <div class="imgtext">实现双赢</div>
         </div>
     </div>
      
     </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
    </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
    name:'playpage',
   components:{
       Header,
       Footer
   }
}
</script>
<style lang="scss" scoped>
* {
    
     box-sizing: border-box;
}
.banner {
  height: 600px;
  width: 100%;
  color: #fff;
  
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
  position: relative;
    img[lazy="loading"] {
    margin-top: 150px;
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
	height: 600px;
  width: 100%;
	}
  .qwe{
      .title {
      z-index: 2;
    width: 412px;
    height: 67px;

    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    position: absolute;
    left: 260px;
    top: 230px;
    font-size: 48px;
    
    
    color: #fff;
  }
  .introduce {
      z-index: 2;
    width: 697px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 365px;
    top: 317px;
  }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
  width: 300px;
  height: 80px;

}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
//2
.twobox{
    height: 730px;
    .texttwo{
        padding-top: 80px;
        padding-bottom: 60px;
        font-size: 40px;

font-weight: 500;
color: #333333;
    }
    .imgboxtwo{
        width: 1200px;
         height: 434px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .imgtextwo:nth-child(2){
             .imgone{
                 background: url("./photo/CzRvnmou.webp") no-repeat center;
            background-size: 100% 100%;
             }
        }
         .imgtextwo:nth-child(3){
             .imgone{
                 background: url("./photo/6rgfPdSj.webp") no-repeat center;
            background-size: 100% 100%;
             }
        }
        .imgtextwo{
             width: 380px;
             height: 434px;
        .imgone{
            width: 380px;
            height: 260px;
            background: url("./photo/FwNDzV60.webp") no-repeat center;
            background-size: 100% 100%;
        }
        .textbig {
            padding: 24px;
            height: 174px;
            box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
border-radius: 6px;
text-align: left;
            .wenzione{
                font-size: 24px;

font-weight: 500;
color: #333333;
padding-bottom: 10px;
text-align: left;

            }
            .wenzitwo{
    font-size: 20px;

font-weight: 400;
color: #999999;
text-align: left;

}
        }
 

        }
    }
}
//3
.threebox{
    height: 720px;
    background: url("./photo/Nwr1HD4p.webp") no-repeat center;
    background-size: 100% 100%;
    padding-top: 196px;
    .threeone{
        font-size: 40px;

font-weight: 500;
color: #FFFFFF;
padding-bottom: 24px;
    }
    .threetwo{
        font-size: 24px;
        width: 1200px;
        margin: 0 auto;

font-weight: 400;
color: #FFFFFF;
padding-bottom: 48px;
line-height: 48px;
    }
    .threethree{
        font-size: 40px;

font-weight: 500;
color: #FFFFFF;
padding-bottom: 24px;
    }
    .threefour{
        font-size: 24px;

font-weight: 400;
color: #FFFFFF;

    }

}
//4 
.fourbox{
    height: 546px;
    padding-top: 100px;
    .textfour{
        font-size: 40px;

font-weight: 500;
color: #333333;
padding-bottom: 60px;
    }
    .imgfour{
        width: 1200px;
        height: 230px;
        margin: 0 auto;
        display: flex;
    justify-content: space-between;
.fourboximg{
    width: 278px;
    height: 230px;
    padding-top:48px;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    .imgsm{
        width: 76px;
        height: 76px;
        background: url("./photo/fiveyi.png") no-repeat center;
        background-size: 100% 100%;
        margin-left: 101px;
    }
    .imgtext{
        padding-top: 14px;
        font-size: 20px;

font-weight: 500;
color: #333333;

    }
}
.fourboximg:nth-child(2){
 .imgsm{
         background: url("./photo/fiveer.png") no-repeat center;
        background-size: 100% 100%;
    }
}
.fourboximg:nth-child(3){
 .imgsm{
        background: url("./photo/fivesan.png") no-repeat center;
        background-size: 100% 100%;
    }
}
.fourboximg:nth-child(4){
 .imgsm{
        background: url("./photo/fivesi.png") no-repeat center;
        background-size: 100% 100%;
    }
}
    }
}
.mork{
    position: absolute;
    left: 0;
    top: 0;
width: 100%;
height: 100%;
background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
opacity: 0.63;

}
</style>